/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {DataList} from '@servicetitan/design-system';
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    pre: "pre",
    code: "code",
    p: "p",
    strong: "strong",
    h1: "h1"
  }, _provideComponents(), props.components);
  if (!DataList) _missingMdxReference("DataList", false);
  if (!DataList.HeaderCell) _missingMdxReference("DataList.HeaderCell", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    id: "spacing-overview",
    style: {
      position: "relative"
    }
  }, "Spacing Overview", React.createElement(_components.a, {
    href: "#spacing-overview",
    "aria-label": "spacing overview permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\n---\n<img src=\"/images/spacing/scale-example.png\" style={{width: '100%'}} />\n")), "\n", React.createElement(_components.p, null, "Anvil uses partials amounts of a ", React.createElement(_components.strong, null, "base-8"), " spacing scale. A half value of ", React.createElement(_components.code, null, "4px"), " is also included. The scale starts at ", React.createElement(_components.code, null, "8px"), ", scaling up to ", React.createElement(_components.code, null, "128px"), "."), "\n", React.createElement(_components.h1, {
    id: "css-utilities",
    style: {
      position: "relative"
    }
  }, "CSS Utilities", React.createElement(_components.a, {
    href: "#css-utilities",
    "aria-label": "css utilities permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Anvil provides utility classes and variables for common spacing needs."), "\n", React.createElement(_components.h2, {
    id: "spacing",
    style: {
      position: "relative"
    }
  }, "Spacing", React.createElement(_components.a, {
    href: "#spacing",
    "aria-label": "spacing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(DataList, {
    className: "DocsTable",
    header: [React.createElement(DataList.HeaderCell, {
      key: 0,
      name: "a",
      content: "Variable"
    }), React.createElement(DataList.HeaderCell, {
      key: 1,
      name: "b",
      content: "Scale"
    }), React.createElement(DataList.HeaderCell, {
      key: 2,
      name: "c",
      content: "Value"
    })],
    items: [{
      a: 'spacing-0',
      b: '0',
      c: '0'
    }, {
      a: 'spacing-half',
      b: 'half',
      c: '4px '
    }, {
      a: 'spacing-1',
      b: '1',
      c: '8px'
    }, {
      a: 'spacing-2',
      b: '2',
      c: '16px'
    }, {
      a: 'spacing-3',
      b: '3',
      c: '24px'
    }, {
      a: 'spacing-4',
      b: '4',
      c: '32px'
    }, {
      a: 'spacing-5',
      b: '5',
      c: '48px'
    }, {
      a: 'spacing-6',
      b: '6',
      c: '64px'
    }, {
      a: 'spacing-7',
      b: '7',
      c: '96px'
    }, {
      a: 'spacing-8',
      b: '8',
      c: '128px'
    }]
  }), "\n", React.createElement(_components.h1, {
    id: "margins--padding",
    style: {
      position: "relative"
    }
  }, "Margins & Padding", React.createElement(_components.a, {
    href: "#margins--padding",
    "aria-label": "margins  padding permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Utility classes for margins and paddings exist. The format is ", React.createElement(_components.code, null, ".p-<scale>"), " for padding, and ", React.createElement(_components.code, null, ".m-<scale>"), " for margin."), "\n", React.createElement(_components.h2, {
    id: "examples",
    style: {
      position: "relative"
    }
  }, "Examples:", React.createElement(_components.a, {
    href: "#examples",
    "aria-label": "examples permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\n---\n<div className=\"bg-white d-f align-items-center justify-content-between p-3\" style={{height: '120px'}}>\n\t<span className=\"bg-green-200 p-0\">\n\t\t<span className=\"bg-blue-200\">.p-0</span>\n\t</span>\n\t<span className=\"bg-green-200 p-half\">\n\t\t<span className=\"bg-blue-200\">.p-half</span>\n\t</span>\n\t<span className=\"bg-green-200 p-1\">\n\t\t<span className=\"bg-blue-200\">.p-1</span>\n\t</span>\n\t<span className=\"bg-green-200 p-2\">\n\t\t<span className=\"bg-blue-200\">.p-2</span>\n\t</span>\n\t<span className=\"bg-green-200 p-3\">\n\t\t<span className=\"bg-blue-200\">.p-3</span>\n\t</span>\n\t<span className=\"bg-green-200 p-4\">\n\t\t<span className=\"bg-blue-200\">.p-4</span>\n\t</span>\n\t<span className=\"bg-green-200 p-5\">\n\t\t<span className=\"bg-blue-200\">.p-5</span>\n\t</span>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\n---\n<div className=\"bg-white d-f align-items-center justify-content-between p-3\" style={{height: '120px'}}>\n\t<div className=\"bg-orange-200\">\n\t\t<div className=\"bg-blue-200 m-0\">.m-0</div>\n\t</div>\n\t<div className=\"bg-orange-200\">\n\t\t<div className=\"bg-blue-200 m-half\">.m-half</div>\n\t</div>\n\t<div className=\"bg-orange-200\">\n\t\t<div className=\"bg-blue-200 m-1\">.m-1</div>\n\t</div>\n\t<div className=\"bg-orange-200\">\n\t\t<div className=\"bg-blue-200 m-2\">.m-2</div>\n\t</div>\n\t<div className=\"bg-orange-200\">\n\t\t<div className=\"bg-blue-200 m-3\">.m-3</div>\n\t</div>\n\t<div className=\"bg-orange-200\">\n\t\t<div className=\"bg-blue-200 m-4\">.m-4</div>\n\t</div>\n\t<div className=\"bg-orange-200\">\n\t\t<div className=\"bg-blue-200 m-5\">.m-5</div>\n\t</div>\n</div>\n")), "\n", React.createElement(_components.h2, {
    id: "directional-examples",
    style: {
      position: "relative"
    }
  }, "Directional Examples", React.createElement(_components.a, {
    href: "#directional-examples",
    "aria-label": "directional examples permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\n---\n<div className=\"bg-white d-f align-items-center justify-content-between p-3\" style={{height: '120px'}}>\n\t<div className=\"bg-orange-200\">\n\t\t<div className=\"bg-blue-200 m-t-3\">.m-t-3</div>\n\t</div>\n\t<div className=\"bg-orange-200\">\n\t\t<div className=\"bg-blue-200 m-r-3\">.m-r-3</div>\n\t</div>\n\t<div className=\"bg-orange-200\">\n\t\t<div className=\"bg-blue-200 m-b-3\">.m-b-3</div>\n\t</div>\n\t<div className=\"bg-orange-200\">\n\t\t<div className=\"bg-blue-200 m-l-3\">.m-l-3</div>\n\t</div>\n\t<div className=\"bg-orange-200\">\n\t\t<div className=\"bg-blue-200 m-b-3\">.m-b-3</div>\n\t</div>\n\t<span className=\"bg-green-200 p-x-3\">\n\t\t<span className=\"bg-blue-200\">.p-x-3</span>\n\t</span>\n\t<span className=\"bg-green-200 p-y-3\">\n\t\t<span className=\"bg-blue-200\">.p-y-3</span>\n\t</span>\n\t<div className=\"bg-white d-f align-items-center justify-content-between p-3\" style={{height: '80px'}}>\n\t\t<div className=\"bg-orange-200\"><div className=\"bg-green-200 p-y-2 m-y-3\">\n\t\t\t<div className=\"bg-blue-200\">.p-y-2.m-y-3</div>\n\t\t\t</div>\n\t\t</div>\n\t</div>\n</div>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\n---\n<div className=\"bg-white d-f align-items-center justify-content-between p-3\" style={{height: '80px'}}>\n\t<div className=\"bg-orange-200 w-100\">\n\t\t<div className=\"bg-blue-200 m-x-auto\" style={{textAlign: 'center', width: '5em'}}>.m-x-auto</div>\n\t</div>\n</div>\n")), "\n", React.createElement(_components.h2, {
    id: "table-breakdown-of-utility-classes",
    style: {
      position: "relative"
    }
  }, "Table breakdown of utility classes", React.createElement(_components.a, {
    href: "#table-breakdown-of-utility-classes",
    "aria-label": "table breakdown of utility classes permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(DataList, {
    className: "DocsTable",
    header: [React.createElement(DataList.HeaderCell, {
      key: 0,
      name: "a",
      content: "Shorthand"
    }), React.createElement(DataList.HeaderCell, {
      key: 1,
      name: "b",
      content: "Description"
    })],
    items: [{
      a: 'm',
      b: 'margin'
    }, {
      a: 'p',
      b: 'padding'
    }, {
      a: 't',
      b: 'top'
    }, {
      a: 'r',
      b: 'right'
    }, {
      a: 'b',
      b: 'bottom'
    }, {
      a: 'l',
      b: 'left'
    }, {
      a: 'x',
      b: 'left & right'
    }, {
      a: 'y',
      b: 'top & bottom'
    }, {
      a: '0',
      b: '0px'
    }, {
      a: 'half',
      b: '4px'
    }, {
      a: '1',
      b: '8px'
    }, {
      a: '2',
      b: '16px'
    }, {
      a: '3',
      b: '24px'
    }, {
      a: '4',
      b: '32px'
    }, {
      a: '5',
      b: '48px'
    }, {
      a: '6',
      b: '64px'
    }, {
      a: '7',
      b: '96px'
    }, {
      a: '8',
      b: '128px'
    }, {
      a: 'auto',
      b: 'auto'
    }]
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
